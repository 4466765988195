import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Pattern } from "../models/pattern.model";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable()
export class PatternsService {
    private jsonUrl: string = '../../assets/data/patterns.json';

    constructor(private http: Http) { }

    getPatterns(): Observable<Pattern[]> {
        return this.http.get(this.jsonUrl).pipe(map(x => x.json())).pipe(map(p => p.map(pp => new Pattern(pp))));
    }
}