export interface IPattern {
    id: number;
    title: string;
    what: string;
    categories: string;
    relations: string;
    useWhen: string;
    why: string;
    age: string;
    how: string;
    materials: string;
    wearout: string;
    repeatability: string;
    affordance: string;
    source1: string;
    source2: string;
    source3: string;
    source4: string;
    source5: string;
    source6: string;
    source7: string;
    source8: string;
    source9: string;
    source10: string;
    source11: string;
    source12: string;
    source13: string;
    source14: string;
}

export class Pattern {
    id: number;
    title: string;
    what: string;
    categories: string[];
    relations: number[];
    useWhen: string;
    why: string;
    age: string;
    how: string;
    materials: string;
    wearout: string;
    repeatability: string;
    affordance: string;
    sources: string[]

    constructor(pattern: IPattern) {
        this.id = pattern.id;
        this.title = pattern.title;
        this.what = pattern.what;

        this.categories = new Array();
        pattern.categories.split(",").forEach(element => {
            var kat = element.trim();
            if(kat !== "") {
                this.categories.push(kat);
            }
        });
        
        this.relations = new Array();
        pattern.relations.split(",").forEach(element => {
            var id = element.trim();
            if(id !== "") {
                this.relations.push(parseInt(id));
            }
        });

        this.useWhen = pattern.useWhen;
        this.why = pattern.why;
        this.age = pattern.age;
        this.how = pattern.how;
        this.materials = pattern.materials;
        this.wearout = pattern.wearout;
        this.repeatability = pattern.repeatability;
        this.affordance = pattern.affordance;
        
        this.sources = new Array();
        this.sources.push(pattern.source1);
        this.sources.push(pattern.source2);
        this.sources.push(pattern.source3);
        this.sources.push(pattern.source4);
        this.sources.push(pattern.source5);
        this.sources.push(pattern.source6);
        this.sources.push(pattern.source7);
        this.sources.push(pattern.source8);
        this.sources.push(pattern.source9);
        this.sources.push(pattern.source10);
        this.sources.push(pattern.source11);
        this.sources.push(pattern.source12);
        this.sources.push(pattern.source13);
        this.sources.push(pattern.source14);
        this.sources = this.sources.filter(q => q !== "");
    }
}