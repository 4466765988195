import { Component, OnInit } from '@angular/core';
import { Pattern } from '../../core/models/pattern.model';
import { PatternsService } from '../../core/services/pattern.service';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-interaction-pattern-list',
  templateUrl: './interaction-pattern-list.component.html',
  styleUrls: ['./interaction-pattern-list.component.css']
})
export class InteractionPatternListComponent implements OnInit {

  patterns: Pattern[];
  all: Pattern[];
  selectedCategory: string;

  constructor(private route: ActivatedRoute, private router: Router, private patternService: PatternsService) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.selectedCategory = params.get('category');
      if(this.selectedCategory === null) {
        this.selectedCategory = '';
      }
      this.filterByCategory();
    });

    this.patternService.getPatterns().subscribe(patterns => {
      this.all = patterns.sort((a, b) => {
        if(a.title == b.title) {
          return 0;
        }
        if(a.title > b.title) {
          return 1;
        }
        return -1;
      })

      this.filterByCategory();
    });
  }

  filterByCategory() {
    if(this.all && this.selectedCategory) {
      this.patterns = this.all.filter(p => p.categories.indexOf(this.selectedCategory) >= 0 || p.wearout === this.selectedCategory || p.repeatability === this.selectedCategory);
    }
    else {
      this.patterns = this.all;
    }
  }

  filter(category: string) {
    if (category !== null && category !== '') {
      this.router.navigate([], {
        queryParams: {
          category: category
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  hasImage(pattern: Pattern):boolean {
    return pattern.sources.length > 0;
  }

  getFirstImage(pattern: Pattern):string {
    return './assets/thumbs/' + pattern.sources[0];
  }

}
