import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PatternsService } from './core/services/pattern.service';
import { GalleryShownService } from './core/services/gallery-shown.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Interaktionspattern in Kinderbücher';
  categories: string[] = new Array();
  selectedCategory: string = '';
  isGalleryVisible: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private patternService: PatternsService, private galleryShownService: GalleryShownService) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.selectedCategory = params.get('category');
      if(this.selectedCategory === null) {
        this.selectedCategory = '';
      }
    });

    this.galleryShownService.changeEmitted$.subscribe(
      isGalleryVisible => {
        this.isGalleryVisible = isGalleryVisible;
      });

    this.patternService.getPatterns().subscribe(patterns => {
      patterns.forEach(pattern => {
        pattern.categories.forEach(kategorie => {
          if (this.categories.indexOf(kategorie) < 0) {
            this.categories.push(kategorie);
          }
        });
        if(this.categories.indexOf(pattern.wearout) < 0) {
          this.categories.push(pattern.wearout);
        }
        if(this.categories.indexOf(pattern.repeatability) < 0) {
          this.categories.push(pattern.repeatability);
        }
      });
      this.categories.sort();
    });
  }

  filter(category: string) {
    if (category !== null && category !== '') {
      this.router.navigate(['/'], {
        queryParams: {
          category: category
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  galleryVisibleEventHandler($event: boolean) {
    this.isGalleryVisible = $event;
  }
}
