import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InteractionPatternListComponent } from './interaction-pattern-list/interaction-pattern-list.component';
import { InteractionPatternDetailComponent } from './interaction-pattern-detail/interaction-pattern-detail.component';


const routes: Routes = [
  {
    path: 'patterns',
    component: InteractionPatternListComponent
  },
  {
    path: 'patterns/:patternId',
    component: InteractionPatternDetailComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InteractionPatternRoutingModule { }
