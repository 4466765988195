import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pattern } from '../../core/models/pattern.model';
import { PatternsService } from '../../core/services/pattern.service';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";
import { GalleryShownService } from '../../core/services/gallery-shown.service';

@Component({
  selector: 'app-interaction-pattern-detail',
  templateUrl: './interaction-pattern-detail.component.html',
  styleUrls: ['./interaction-pattern-detail.component.css']
})
export class InteractionPatternDetailComponent implements OnInit {

  @ViewChild(NgxImageGalleryComponent, { static: false }) ngxImageGallery: NgxImageGalleryComponent;

  all: Pattern[];
  pattern: Pattern;
  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };
  sideNavZindex: number;

  // gallery images
  images: GALLERY_IMAGE[] = new Array();

  constructor(private route: ActivatedRoute, private router: Router, private patternService: PatternsService, private galleryShownService: GalleryShownService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const patternId = +params.get('patternId');

      this.patternService.getPatterns().subscribe(patterns => {
        this.all = patterns;
        this.pattern = patterns.find(x => x.id === patternId);
        this.images = new Array();

        this.pattern.sources.forEach(source => {
          if (source.endsWith('.jpg')) {
            this.images.push({
              url: '../../../assets/examples/' + source,
              thumbnailUrl: '../../../assets/examples/' + source
            });
          }
        });
      });
    });
  }

  getPatternName(id: number): string {
    return this.all.find(x => x.id == id).title;
  }

  getAffordanceIcon(affordance: string): string {
    switch (affordance.toLowerCase()) {
      case "hoch": return "mood";
      case "mittel": return "sentiment_satisfied";
      case "tief": return "mood_bad";
    }
  }

  filter(category: string) {
    if (category !== null && category !== '') {
      this.router.navigate(['/'], {
        queryParams: {
          category: category
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  hasRelations(pattern: Pattern): boolean {
    return pattern.relations.length > 0;
  }

  // METHODS
  // open gallery
  openGallery(source: string) {
    if (source.indexOf('.mov') < 0) {
      var index = this.images.findIndex(img => img.url.endsWith(source));
      this.ngxImageGallery.open(index);
    }
  }

  // close gallery
  closeGallery() {
    this.ngxImageGallery.close();
  }

  // set new active(visible) image in gallery
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }

  // next image in gallery
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }

  // prev image in gallery
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }

  /**************************************************/

  // EVENTS
  // callback on gallery opened
  galleryOpened(index) {
    this.galleryShownService.emitChange(true);
  }

  // callback on gallery closed
  galleryClosed() {
    this.galleryShownService.emitChange(false);
  }

}
