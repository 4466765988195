import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { FlexLayoutModule } from '@angular/flex-layout';

import { PatternsService } from '../core/services/pattern.service'

import { InteractionPatternRoutingModule } from './interaction-pattern-routing.module';
import { InteractionPatternListComponent } from './interaction-pattern-list/interaction-pattern-list.component';
import { InteractionPatternDetailComponent } from './interaction-pattern-detail/interaction-pattern-detail.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';


@NgModule({
  declarations: [InteractionPatternListComponent, InteractionPatternDetailComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatChipsModule,
    MatGridListModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    FlexLayoutModule,
    InteractionPatternRoutingModule,
    NgxImageGalleryModule
  ],
  providers: [
    PatternsService
  ]
})
export class InteractionPatternModule { }
